import logo from './logo.svg';
import Nav from './components/Nav'
import Footer from './components/Footer'
import './App.css';

function App() {
  return (
    <div>
    <Nav></Nav>
    <Footer></Footer>
    </div>
  );
}

export default App;
